import React, { FunctionComponent } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import * as sidebarStyles from './Sidebar.module.css'

interface SidebarProps {
  thisID: number
}

const Sidebar: FunctionComponent<SidebarProps> = (props) => {
  type dataType = {
    allWpPost: {
      edges: {
        node: {
          databaseId: number
          slug: string
          title: string
        }
      }[]
    }
  }
  const data: dataType = useStaticQuery(graphql`
    query SidebarQuery {
      allWpPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            databaseId
            slug
            title
          }
        }
      }
    }
  `)
  return (
    <div className={`${sidebarStyles.sidebar}`}>
      <h2>Other Posts</h2>
      {data.allWpPost.edges.map((post) => {
        if (post.node.databaseId === props.thisID) {
          return null
        }
        return (
          <Link
            key={post.node.slug}
            to={`/blog/${post.node.slug}`}
            className={`${sidebarStyles.postLink}`}
            dangerouslySetInnerHTML={{ __html: post.node.title }}
          />
        )
      })}
    </div>
  )
  // }
}

export default Sidebar
