import React, { useContext, FunctionComponent } from 'react'
import { GatsbyImage, IGatsbyImageData, getSrc } from 'gatsby-plugin-image'
import Sidebar from './Sidebar'
import SEO from './SEO'
import Nav, { updateNav } from './Nav'
import { GlobalContext, ContextType } from './GlobalContextProvider'
import * as blogPostStyles from './BlogPost.module.css'
import * as blogStyles from './Blog.module.css'

export interface blogPostProps {
  data: {
    wpPost: {
      databaseId: number
      title: string
      content: string
      date: string
      author: {
        node: {
          name: string
        }
      }
      featuredImage: {
        node: {
          caption: string
          localFile: {
            childImageSharp: {
              gatsbyImageDataFluid: IGatsbyImageData
              gatsbyImageDataFixed: IGatsbyImageData
            }
          }
        }
      }
    }
  }
}

const formatDate = (date: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const day = parseInt(date.substr(8, 2), 10)
  const month = parseInt(date.substr(5, 2), 10) - 1
  const year = date.substr(0, 4)
  return `- ${months[month]} ${day}, ${year}`
}

export const BlogPost: FunctionComponent<blogPostProps> = (props) => {
  const { nav } = useContext(GlobalContext) as ContextType
  if (nav.currentPage !== null) {
    updateNav(nav.currentPage.substr(0, 9) !== '/blogPage')
  }
  let featMedia: JSX.Element
  if (props.data.wpPost.featuredImage === null) {
    featMedia = <div />
  } else {
    featMedia = (
      <GatsbyImage
        image={
          props.data.wpPost.featuredImage.node.localFile.childImageSharp
            .gatsbyImageDataFluid
        }
        className="featImage"
        alt={props.data.wpPost.featuredImage.node.caption}
      />
    )
  }
  const featImage = getSrc(
    props.data.wpPost.featuredImage.node.localFile.childImageSharp
      .gatsbyImageDataFixed
  )
  return (
    <div>
      <SEO
        title={props.data.wpPost.title}
        description={props.data.wpPost.content}
        image={featImage ?? ''}
        type="article"
      />
      <header className={blogStyles.blogHeader}>
        <div className={blogStyles.title}>Our European Tour</div>
      </header>
      <div className={blogPostStyles.contentWrap}>
        {featMedia}
        <div
          className={blogPostStyles.title}
          dangerouslySetInnerHTML={{ __html: props.data.wpPost.title }}
        />
        <div className={blogPostStyles.authorLine}>
          (By {props.data.wpPost.author.node.name}
          <span className="date">{formatDate(props.data.wpPost.date)}</span>)
        </div>
        <div
          className={blogPostStyles.content}
          dangerouslySetInnerHTML={{ __html: props.data.wpPost.content }}
        />
        <Sidebar thisID={props.data.wpPost.databaseId} />
      </div>
      <Nav />
    </div>
  )
}
