import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { BlogPost, blogPostProps } from '../components/BlogPost'

const BlogPostTemplate: FunctionComponent<blogPostProps> = ({ data }) => (
  <Layout>
    <BlogPost data={data} />
  </Layout>
)

export default BlogPostTemplate

export const query = graphql`
  query($id: Int!) {
    wpPost(databaseId: { eq: $id }) {
      databaseId
      title
      content
      date
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          caption
          localFile {
            childImageSharp {
              gatsbyImageDataFluid: gatsbyImageData(layout: FULL_WIDTH)
              gatsbyImageDataFixed: gatsbyImageData(
                width: 450
                height: 450
                layout: FIXED
              )
            }
          }
        }
      }
    }
  }
`
